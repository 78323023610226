import type { NavLink } from "@kippie/ui/dist/runtime/components";
import type { LinkStoryblok } from "~/types/storyblok";
import type { LinkGroupStoryblok } from "~/types/storyblok-generic";

export function transformUrl(url: string): string {
	const protocols = ["/", "https://", "mailto:", "tel:", "tel:", "http://", "//", "#"];

	// Remove extra spaces from url string
	url = url.trim();

	// remove trailing slash
	url = url.replace(/\/$/, "");

	// ensure internal link starts with slash
	return protocols.some((item) => url.startsWith(item)) ? url : `/${url}`;
}

export function transformToLink(arr: LinkStoryblok[]): NavLink[] {
	return arr.map(({ link, label }) => {
		return { label, url: transformUrl(link.cached_url) };
	});
}

export function transformToLinkGroup(arr: LinkGroupStoryblok[]): { title: string; links: NavLink[] }[] {
	return arr.map((group) => {
		return { title: group.title, links: transformToLink(group.links) };
	});
}
